import { withApollo } from "@apollo/client/react/hoc";
import { UUID } from "@screencloud/uuid";
import { Icon, LoaderBar } from "@screencloud/screencloud-ui-components";
import memoize from "memoize-one";
import { PureComponent } from "react";
import { AppContextType } from "src/AppContextProvider/type";
import { AppContext } from "../../AppContextProvider/AppContext";
import { SortingActions } from "../../constants/constants";
import { getChannelCover } from "../../helpers/channelHelper";
import {
  UseSearchChannelPicker,
  useSearchChannelPicker,
} from "./hooks/useSearchChannelPicker";
import { Channel, Maybe, Playlist } from "../../types.g";
import { compose } from "../../utils/compose";
import ChannelPickerItem from "./item";

export interface ApolloProps {
  data: UseSearchChannelPicker;
}
interface ChannelsSearchProps {
  callback?: (id: string, channel: Partial<Channel>[]) => void;
  searchString: string;
  spaceId?: UUID;
}

const withData = compose(withApollo, (Component) => (props: any) => {
  return (
    <Component
      {...props}
      data={useSearchChannelPicker(props.searchString, props.spaceId as UUID)}
    />
  );
});

export interface PlaylistPickerState {
  isOrderByAscending: boolean;
  channelCount: number;
  searchString: string;
  selectedChannel: string;
  selectedData: Maybe<Partial<Playlist>>;
  sortBy: SortingActions;
  isFetchMore: boolean;
}
class SearchPlaylistPickerComponent extends PureComponent<
  ChannelsSearchProps & ApolloProps,
  PlaylistPickerState
> {
  public static contextType = AppContext;
  public context: AppContextType;

  public getPlaylistItemsDuration = memoize((list: any[]) => {
    return list.reduce((sum, item) => {
      const duration = item.duration;
      return sum + duration * 1000;
    }, 0);
  });

  constructor(props: ChannelsSearchProps & ApolloProps) {
    super(props);
    this.state = {
      isFetchMore: false,
      isOrderByAscending: true,
      channelCount: 0,
      searchString: "",
      selectedChannel: "",
      selectedData: {},
      sortBy: SortingActions.SORT_BY_NAME,
    };
  }

  public toggleSelect = (id, channel) => {
    if (this.props.callback) {
      this.props.callback(id, channel);
    }

    this.setState({
      selectedChannel: id,
      selectedData: channel,
    });
  };

  public renderOrderCaretIcon = (): JSX.Element => {
    const orderIcon = this.state.isOrderByAscending ? "caret-down" : "caret-up";
    return <Icon className="caret-order" name={orderIcon} />;
  };

  public render() {
    if (this.props.data?.channels) {
      const channels = this.props.data.channels;

      return (
        <>
          {channels.map((channel, index) => {
            if (channel) {
              const coverData = getChannelCover(
                channel as Channel,
                this.context.secureMediaPolicy,
              );
              const isDraftPublished = channel.draft
                ? channel.draft.isPublished!
                : false;
              const isChannelOwner =
                this.context.user.settings.spaceId === channel.spaceId;
              return (
                channel !== null && (
                  <ChannelPickerItem
                    selected={channel.id === this.state.selectedChannel}
                    key={`channel-item-${channel.id}-${Math.random()}`}
                    background={coverData}
                    channel={channel}
                    callBack={this.toggleSelect}
                    spaceExist={false}
                    isChannelOwner={isChannelOwner}
                    isPublish={isDraftPublished}
                    index={index}
                  />
                )
              );
            } else {
              return null;
            }
          })}
          {this.props.data.renderFetchMoreButton}
        </>
      );
    } else {
      return <LoaderBar />;
    }
  }
}

export default withData(SearchPlaylistPickerComponent);
