import {
  Button,
  ButtonGroup,
  Checkbox,
  Dropdown,
  DropdownAddition,
  Icon,
  Input,
  Popover,
  TextArea,
} from "@screencloud/screencloud-ui-components";
import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { DASHBOARDS_DEFAULT_KEEP_ALIVE } from "../../../../constants/constants";
import { timeZoneList } from "../../../../constants/timezones";
import { PrimaryButton } from "../../../../helpers/whiteLabel";
import { ActionMessage, ActionMessageType } from "../reducers";
import { ActionEditorContainer } from "../styles";

import {
  ClickAction,
  DelayDurationAction,
  DelayElementAction,
  EnterTextAction,
  HoverAction,
  LoopAction,
  RenderDurationAction,
  RenderSingleAction,
  ScrollAction,
  SessionKeepAliveAction,
  SiteRecorderAction,
  SiteRecorderActionType,
} from "./models";

type DispatchFunction = (action: ActionMessage) => void;

export const renderActionEditor = (
  type: SiteRecorderActionType,
  dispatch: DispatchFunction,
  action?: SiteRecorderAction,
) => {
  switch (type) {
    case SiteRecorderActionType.Click:
      return <ClickEditor action={action!} dispatch={dispatch} />;
    case SiteRecorderActionType.DelayDuration:
      return <DelayTimeEditor action={action} dispatch={dispatch} />;
    case SiteRecorderActionType.DelayElement:
      return <DelayElementEditor action={action} dispatch={dispatch} />;
    case SiteRecorderActionType.EnterOneTimeCode:
    case SiteRecorderActionType.EnterPassword:
    case SiteRecorderActionType.EnterText:
    case SiteRecorderActionType.EnterUsername:
      return <EnterTextEditor action={action!} dispatch={dispatch} />;
    case SiteRecorderActionType.Hover:
      return <HoverEditor action={action} dispatch={dispatch} />;
    case SiteRecorderActionType.Loop:
      return <LoopEditor action={action} dispatch={dispatch} />;
    case SiteRecorderActionType.RenderDuration:
      return <RenderDurationEditor action={action} dispatch={dispatch} />;
    case SiteRecorderActionType.RenderSingle:
      return <RenderSingleEditor action={action} dispatch={dispatch} />;
    case SiteRecorderActionType.Scroll:
      return <ScrollEditor action={action} dispatch={dispatch} />;
    case SiteRecorderActionType.SessionKeepAlive:
      return <SessionKeepAliveEditor action={action} dispatch={dispatch} />;
    default:
      return <></>;
  }
};

interface ClickEditorProps {
  action: ClickAction;
  dispatch: DispatchFunction;
}

const buttonOptions = [
  {
    key: 0,
    value: 0,
    text: "Left",
    icon: "mouse-left-click",
  },
  {
    key: 1,
    value: 1,
    text: "Middle",
    icon: "mouse-middle-click",
  },
  {
    key: 2,
    value: 2,
    text: "Right",
    icon: "mouse-right-click",
  },
];

const ClickEditor: FunctionComponent<ClickEditorProps> = ({
  action,
  dispatch,
}) => {
  const [button, setButton] = useState(action.config.button);

  return (
    <ActionEditorContainer>
      <div className="editor-field">
        <div className="field-info">
          <div className="field-name">Mouse Click</div>
        </div>
        <ButtonGroup>
          {buttonOptions.map((option) => (
            <Button
              data-cy={`${option.text}-click`}
              value={option.value}
              active={button === option.key}
              onClick={(e, data) => setButton(data.value as number)}
            >
              <Icon
                name={option.icon}
                className={`${option.icon} ${
                  button === option.key ? "active" : ""
                }`}
              />
              <FormattedMessage
                id={`ui_component.site.recorder.${option.text}_click`}
                defaultMessage={option.text}
              />
            </Button>
          ))}
        </ButtonGroup>
      </div>
      <div className="action-button-container">
        <PrimaryButton
          fullWidth
          mini
          onClick={() => {
            let clickType = "Click";

            if (button === 1) {
              clickType = "Middle click";
            } else if (button === 2) {
              clickType = "Right click";
            }

            action.name = `${clickType}${action.name.substring(
              action.name.indexOf(" on "),
            )}`;
            action.config.button = button;

            dispatch({ type: ActionMessageType.SaveActionEditor });
          }}
          className="editor-button"
        >
          <FormattedMessage id="common.text.save" defaultMessage="Save" />
        </PrimaryButton>
        <Button
          fullWidth
          mini
          onClick={() =>
            dispatch({ type: ActionMessageType.CloseActionEditor })
          }
          className="editor-button"
        >
          <FormattedMessage
            id="ui_component.common.label.cancel"
            defaultMessage="Cancel"
          />
        </Button>
      </div>
    </ActionEditorContainer>
  );
};

interface DelayElementEditorProps {
  action?: DelayElementAction;
  dispatch: DispatchFunction;
}

const DelayElementEditor: FunctionComponent<DelayElementEditorProps> = ({
  action,
  dispatch,
}) => {
  const [isSelecting, setSelecting] = useState(action === undefined);
  const [selector, setSelector] = useState(action?.config.selector ?? "");

  useEffect(() => {
    dispatch({
      type: ActionMessageType.ToggleElementSelection,
      payload: action === undefined,
    });
  }, []);

  useEffect(() => {
    if (action && action.config.selector) {
      setSelecting(false);
      setSelector(action.config.selector);
    }
  }, [action]);

  return (
    <ActionEditorContainer>
      <h2>Delay until visible</h2>
      {isSelecting ? (
        <>
          <p>Click on an element to select it.</p>
        </>
      ) : (
        <>
          <div className="editor-field">
            <div className="field-info">
              <div className="field-name">Selected element</div>
              <Button
                mini
                onClick={() => {
                  setSelecting(true);
                  setSelector("");
                  dispatch({
                    type: ActionMessageType.ToggleElementSelection,
                    payload: true,
                  });
                }}
                className="editor-inline-button"
              >
                <FormattedMessage
                  id="common.text.change"
                  defaultMessage="Change"
                />
              </Button>
            </div>
            <TextArea value={selector} disabled />
          </div>
        </>
      )}
      <div className="action-button-container">
        <PrimaryButton
          fullWidth
          mini
          onClick={() => dispatch({ type: ActionMessageType.SaveActionEditor })}
          className="editor-button"
          disabled={isSelecting}
        >
          <FormattedMessage id="common.text.save" defaultMessage="Save" />
        </PrimaryButton>
        <Button
          fullWidth
          mini
          onClick={() =>
            dispatch({ type: ActionMessageType.CloseActionEditor })
          }
          className="editor-button"
        >
          <FormattedMessage
            id="ui_component.common.label.cancel"
            defaultMessage="Cancel"
          />
        </Button>
      </div>
    </ActionEditorContainer>
  );
};

interface HoverEditorProps {
  action?: HoverAction;
  dispatch: DispatchFunction;
}

const HoverEditor: FunctionComponent<HoverEditorProps> = ({
  action,
  dispatch,
}) => {
  const selector = action?.config.selector ?? "";

  useEffect(() => {
    dispatch({
      type: ActionMessageType.ToggleElementSelection,
      payload: selector.length === 0,
    });
  }, []);

  return (
    <ActionEditorContainer>
      <h2>Hover over an element</h2>
      {selector.length === 0 ? (
        <>
          <p>Click on the element that you want to hover over.</p>
        </>
      ) : (
        <>
          <div className="editor-field">
            <div className="field-info">
              <div className="field-name">Selected element</div>
              <Button
                mini
                onClick={() =>
                  dispatch({
                    type: ActionMessageType.ToggleElementSelection,
                    payload: true,
                  })
                }
                className="editor-inline-button"
              >
                <FormattedMessage
                  id="common.text.change"
                  defaultMessage="Change"
                />
              </Button>
            </div>
            <TextArea value={selector} disabled />
          </div>
        </>
      )}
      <div className="action-button-container">
        <PrimaryButton
          fullWidth
          mini
          onClick={() => dispatch({ type: ActionMessageType.SaveActionEditor })}
          className="editor-button"
          disabled={selector.length === 0}
        >
          <FormattedMessage id="common.text.save" defaultMessage="Save" />
        </PrimaryButton>
        <Button
          fullWidth
          mini
          onClick={() =>
            dispatch({ type: ActionMessageType.CloseActionEditor })
          }
          className="editor-button"
        >
          <FormattedMessage
            id="ui_component.common.label.cancel"
            defaultMessage="Cancel"
          />
        </Button>
      </div>
    </ActionEditorContainer>
  );
};

const textTypeOptions = [
  { key: "text", value: "text", text: "Text" },
  { key: "username", value: "username", text: "Username" },
  { key: "password", value: "password", text: "Password" },
  { key: "otp", value: "otp", text: "OTP" },
];

interface EnterTextEditorProps {
  action: EnterTextAction;
  dispatch: DispatchFunction;
}

const textActionRegex = /Enter (text|username|password)/;

const EnterTextEditor: FunctionComponent<EnterTextEditorProps> = ({
  action,
  dispatch,
}) => {
  let initialType = textTypeOptions[0].value;

  switch (action.type) {
    case SiteRecorderActionType.EnterUsername:
      initialType = textTypeOptions[1].value;
      break;
    case SiteRecorderActionType.EnterPassword:
      initialType = textTypeOptions[2].value;
      break;
    case SiteRecorderActionType.EnterOneTimeCode:
      initialType = textTypeOptions[3].value;
      break;
  }
  const [selectedType, setSelectedType] = useState(initialType);

  const [textInput, setTextInput] = useState(action.config.input);
  const [secretKeyInput, setSecretKeyInput] = useState(
    action.config.secretKey || "",
  );
  const [credentialNameInput, setCredentialNameInput] = useState(
    action.config.credentialName || "",
  );

  const isValid = useMemo(() => textInput.trim().length > 0, [textInput]);
  return (
    <ActionEditorContainer>
      <div className="editor-field">
        <div className="field-info">
          <div className="field-name">Type</div>
        </div>
        <Dropdown
          options={textTypeOptions}
          value={selectedType}
          onChange={(e, data) => setSelectedType(data.value as string)}
          selection
          fluid
        />
      </div>
      <div className="editor-field">
        <Input
          value={textInput}
          type={selectedType === "password" ? "password" : "text"}
          onChange={(event) => setTextInput(event.target.value)}
          fluid
        />
      </div>
      {selectedType === "otp" && (
        <>
          <div className="editor-field">
            <div className="field-info">
              <div className="field-name">Credential Name</div>
              <div className="tooltip">
                <Popover
                  inverted
                  position="top center"
                  content={
                    <FormattedMessage
                      id="ui_component.site.recorder.enter_credential_tooltip"
                      defaultMessage="Your Credential Name can be anything that helps you identify this credential."
                    />
                  }
                  trigger={<Icon name="info" className="popover-icon" />}
                />
              </div>
            </div>
            <Input
              value={credentialNameInput}
              type={"text"}
              onChange={(event) => setCredentialNameInput(event.target.value)}
              error={credentialNameInput.length < 1}
              placeholder={"Input a Credential Name"}
              fluid
            />
          </div>
          <div className="editor-field">
            <div className="field-info">
              <div className="field-name">Secret Key</div>
              <div className="tooltip">
                <Popover
                  inverted
                  position="top center"
                  content={
                    <FormattedMessage
                      id="ui_component.site.recorder.secret_key_tooltip"
                      defaultMessage="Your secret key is a random string of characters, it can contain spaces. Don't have your secret key? No problem, our documentation will walk you through the process of finding it or generating a new one."
                    />
                  }
                  trigger={<Icon name="info" className="popover-icon" />}
                />
              </div>
            </div>
            <Input
              value={secretKeyInput}
              type={"text"}
              onChange={(event) => setSecretKeyInput(event.target.value)}
              error={secretKeyInput.length < 1}
              placeholder={"Input Secret Key"}
              fluid
            />
          </div>
        </>
      )}
      <div className="action-button-container">
        <PrimaryButton
          fullWidth
          mini
          onClick={() => {
            switch (selectedType) {
              case "username":
                action.type = SiteRecorderActionType.EnterUsername;
                action.name = action.name.replace(
                  textActionRegex,
                  "Enter username",
                );
                break;
              case "password":
                action.type = SiteRecorderActionType.EnterPassword;
                action.name = action.name.replace(
                  textActionRegex,
                  "Enter password",
                );
                break;
              case "otp":
                action.type = SiteRecorderActionType.EnterOneTimeCode;
                action.name = action.name.replace(textActionRegex, "OTP");
                action.config.secretKey = secretKeyInput;
                action.config.credentialName = credentialNameInput;
                break;
              case "text":
                action.type = SiteRecorderActionType.EnterText;
                action.name = action.name.replace(
                  textActionRegex,
                  "Enter text",
                );
                break;
              default:
                break;
            }

            action.config.input = textInput.trim();
            dispatch({ type: ActionMessageType.SaveActionEditor });
          }}
          className="editor-button"
          disabled={!isValid}
        >
          <FormattedMessage id="common.text.save" defaultMessage="Save" />
        </PrimaryButton>
        <Button
          fullWidth
          mini
          onClick={() =>
            dispatch({ type: ActionMessageType.CloseActionEditor })
          }
          className="editor-button"
        >
          <FormattedMessage
            id="ui_component.common.label.cancel"
            defaultMessage="Cancel"
          />
        </Button>
      </div>
    </ActionEditorContainer>
  );
};

const intervalOptions = [
  { key: "m", value: "m", text: "minutes" },
  { key: "h", value: "h", text: "hours" },
];

interface SessionKeepAliveEditorProps {
  dispatch: DispatchFunction;
  action?: SessionKeepAliveAction;
}

export const SessionKeepAliveEditor: FunctionComponent<
  SessionKeepAliveEditorProps
> = ({ action, dispatch }) => {
  const [refreshInput, setRefreshInput] = useState("");
  const [selectedInterval, setSelectedInterval] = useState(
    intervalOptions[0].value,
  );

  const [actionState] = useState(action);

  const [isIntervalSet, setIsIntervalSet] = useState(
    actionState?.config.interval !== undefined,
  );
  const [isIntervalOff, setIsIntervalOff] = useState(
    actionState?.config.interval === "0m",
  );

  // DropdownAddition component doesn't appear to re-render on value change
  // so need to make sure it's set correctly first time.
  let timeZoneValue = "UTC";

  if (actionState) {
    // validate time zone
    const timeZone = timeZoneList.find(
      (tz) => tz.value === actionState.config.timezone,
    );

    if (timeZone) {
      timeZoneValue = actionState.config.timezone as string;
    }
  }

  const [selectedTimezone, setSelectedTimezone] = useState(timeZoneValue);

  useEffect(() => {
    if (!actionState) {
      return;
    }

    if (
      actionState.config.interval &&
      actionState.config.interval !== DASHBOARDS_DEFAULT_KEEP_ALIVE
    ) {
      const match = actionState?.config.interval?.match(/^([1-9]\d*)([mh])$/)!;

      // since removing seconds option, may not match
      if (match && match.length === 3) {
        setRefreshInput(match[1]);
        setSelectedInterval(match[2]);
      } else {
        setRefreshInput("0");
        setSelectedInterval("m");
        setIsIntervalOff(true);
      }
    } else {
      setRefreshInput("45");
      setSelectedInterval("m");
      setIsIntervalSet(false);
    }
  }, [action]);

  useEffect(() => {
    if (isIntervalOff) {
      setRefreshInput("0");
    }
  }, [isIntervalOff]);
  return (
    <ActionEditorContainer>
      <h1>
        <FormattedMessage
          id="ui_component.site.recorder.actions.advanced_settings"
          defaultMessage="Advanced Settings"
        />
      </h1>
      <h2>
        <FormattedMessage
          id="ui_component.site.recorder.actions.manage_session"
          defaultMessage="Manage session"
        />
      </h2>
      <div className="action-info-text">
        <FormattedMessage
          id="ui_component.site.recorder.refresh_rate_info_text"
          defaultMessage="Keep your session active by automatically refreshing it before expiration. This setting helps maintain your login state and prevents unexpected logouts during automated tasks. To ensure uninterrupted operation, we recommend setting the refresh interval to at least 5 minutes before your session's actual expiration time."
        />
        <a
          href="https://help.screencloud.com/en/articles/10115591-how-to-set-the-dashboard-refresh-rate-in-screencloud-dashboards"
          target="_blank"
          rel="noreferrer"
        >
          <FormattedMessage
            id="ui_component.site.recorder.learn_more"
            defaultMessage="Learn more"
          />
        </a>
      </div>
      <div className="editor-field">
        <div className="field-info">
          <div className="field-name">
            <FormattedMessage
              id="ui_component.site.recorder.refresh_every"
              defaultMessage="Refresh session every"
            />
          </div>
        </div>
        <Input
          labelPosition="right"
          label={
            <Dropdown
              options={intervalOptions}
              value={selectedInterval}
              onChange={(e, data) => setSelectedInterval(data.value as string)}
            />
          }
          value={refreshInput}
          type="number"
          min="1"
          onChange={(event) => setRefreshInput(event.target.value)}
          fluid
          disabled={isIntervalOff}
        />
      </div>
      <div className="editor-field">
        <div className="field-info">
          <div className="field-name">
            <FormattedMessage
              id="ui_component.site.recorder.actions.toogle_session_refresh"
              defaultMessage="Turn off session refresh"
            />
          </div>
        </div>
        <Checkbox
          data-testid="site-interval-toggle"
          toggle
          checked={isIntervalOff}
          onChange={() => setIsIntervalOff(!isIntervalOff)}
        />
      </div>
      <h2>
        <FormattedMessage
          id="ui_component.site.recorder.actions.set_timezone"
          defaultMessage="Dashboard Time Zone"
        />
      </h2>
      <div className="editor-field">
        <div className="field-info">
          <div className="field-name">
            <FormattedMessage
              id="ui_component.site.recorder.timezone_info_text"
              defaultMessage="Align your dashboard to the correct time zone for accurate, time-sensitive data. Choose from the list below."
            />
          </div>
        </div>
        <DropdownAddition
          className="time-zone-dropdown"
          data-testid="time-zone-search-box"
          options={timeZoneList}
          placeholder="Search time zone"
          onChange={(_, data) => {
            const timeZone = timeZoneList.find((tz) => tz.value === data.value);
            if (timeZone) {
              setSelectedTimezone(data.value as string);
            }
          }}
          value={selectedTimezone}
          disableAddition
        />
      </div>
      <div className="button-options">
        <PrimaryButton
          mini
          onClick={() => {
            const interval = isNaN(parseInt(refreshInput))
              ? DASHBOARDS_DEFAULT_KEEP_ALIVE
              : `${parseInt(refreshInput)}${selectedInterval}`;

            const config = { interval, timezone: selectedTimezone };
            dispatch({
              type: ActionMessageType.UpdateActionEditor,
              payload: {
                action: actionState
                  ? { ...actionState, config }
                  : new SessionKeepAliveAction("Manage session", config),
              },
            });
            dispatch({ type: ActionMessageType.SaveActionEditor });
          }}
          className="editor-button"
        >
          <FormattedMessage id="common.text.save" defaultMessage="Save" />
        </PrimaryButton>
        {isIntervalSet && (
          <Button
            mini
            onClick={() =>
              dispatch({ type: ActionMessageType.CloseActionEditor })
            }
            className="editor-button"
          >
            <FormattedMessage
              id="ui_component.common.label.cancel"
              defaultMessage="Cancel"
            />
          </Button>
        )}
      </div>
    </ActionEditorContainer>
  );
};

const LoopEditor: FunctionComponent<{
  dispatch: DispatchFunction;
  action?: LoopAction;
}> = ({ dispatch, action }) => {
  const [loopsInput, setLoopsInput] = useState(
    String(action?.config.loops ?? ""),
  );
  const isValid = useMemo(() => !isNaN(parseInt(loopsInput)), [loopsInput]);

  return (
    <ActionEditorContainer>
      <h2>Repeat steps in a loop</h2>
      <div className="editor-field">
        <div className="field-info">
          <div className="field-name">Loops</div>
        </div>
        <Input
          placeholder="3"
          value={loopsInput}
          type="number"
          min="1"
          onChange={(event) => setLoopsInput(event.target.value)}
          fluid
        />
      </div>
      <div className="action-button-container">
        <PrimaryButton
          fullWidth
          mini
          onClick={() => {
            const loops = parseInt(loopsInput);

            if (action) {
              action.config.loops = loops;
            } else {
              dispatch({
                type: ActionMessageType.UpdateActionEditor,
                payload: {
                  action: new LoopAction("Loop", {
                    actions: [],
                    loops,
                  }),
                },
              });
            }

            dispatch({ type: ActionMessageType.SaveActionEditor });
          }}
          className="editor-button"
          disabled={!isValid}
        >
          <FormattedMessage id="common.text.save" defaultMessage="Save" />
        </PrimaryButton>
        <Button
          fullWidth
          mini
          onClick={() =>
            dispatch({ type: ActionMessageType.CloseActionEditor })
          }
          className="editor-button"
        >
          <FormattedMessage
            id="ui_component.common.label.cancel"
            defaultMessage="Cancel"
          />
        </Button>
      </div>
    </ActionEditorContainer>
  );
};

interface RenderSingleEditorProps {
  action?: RenderSingleAction;
  dispatch: DispatchFunction;
}

const RenderSingleEditor: FunctionComponent<RenderSingleEditorProps> = ({
  action,
  dispatch,
}) => {
  const [isSelecting, setSelecting] = useState(action === undefined);
  const [selector, setSelector] = useState(action?.config.selector ?? "");

  useEffect(() => {
    dispatch({
      type: ActionMessageType.ToggleElementSelection,
      payload: action === undefined,
    });
  }, []);

  useEffect(() => {
    if (action && action.config.selector) {
      setSelecting(false);
      setSelector(action.config.selector);
    }
  }, [action]);

  return (
    <ActionEditorContainer>
      <h2>Take a snapshot</h2>
      {isSelecting ? (
        <>
          <p>Click on the element that you want to take a snapshot of.</p>
          <Button
            fullWidth
            mini
            onClick={() => {
              setSelecting(false);
              dispatch({
                type: ActionMessageType.ToggleElementSelection,
                payload: false,
              });
            }}
            className="editor-button"
          >
            <FormattedMessage
              id="ui_component.site.recorder.select_page"
              defaultMessage="Select the entire page"
            />
          </Button>
        </>
      ) : (
        <>
          <div className="editor-field">
            <div className="field-info">
              <div className="field-name">Selected element</div>
              <Button
                mini
                onClick={() => {
                  setSelecting(true);
                  setSelector("");
                  dispatch({
                    type: ActionMessageType.ToggleElementSelection,
                    payload: true,
                  });
                }}
                className="editor-inline-button"
              >
                <FormattedMessage
                  id="common.text.change"
                  defaultMessage="Change"
                />
              </Button>
            </div>
            <label className="snapshot-label">
              {selector.length > 0 ? selector : "Entire page"}
            </label>
          </div>
        </>
      )}
      <div className="action-button-container">
        <PrimaryButton
          fullWidth
          mini
          onClick={() => {
            if (action) {
              if (selector.length === 0) {
                action.name = "Take a snapshot of the page";
                action.config.url = "";
                action.config.element = undefined;
                action.config.selector = undefined;
              }
            } else {
              dispatch({
                type: ActionMessageType.UpdateActionEditor,
                payload: {
                  action: new RenderSingleAction(
                    "Take a snapshot of the page",
                    {
                      url: "",
                      element: undefined,
                      selector: undefined,
                    },
                  ),
                },
              });
            }

            dispatch({ type: ActionMessageType.SaveActionEditor });
          }}
          className="editor-button"
          disabled={isSelecting}
        >
          <FormattedMessage id="common.text.save" defaultMessage="Save" />
        </PrimaryButton>
        <Button
          fullWidth
          mini
          onClick={() =>
            dispatch({ type: ActionMessageType.CloseActionEditor })
          }
          className="editor-button"
        >
          <FormattedMessage
            id="ui_component.common.label.cancel"
            defaultMessage="Cancel"
          />
        </Button>
      </div>
    </ActionEditorContainer>
  );
};

interface RenderDurationEditorProps {
  action?: RenderDurationAction;
  dispatch: DispatchFunction;
}

const RenderDurationEditor: FunctionComponent<RenderDurationEditorProps> = ({
  action,
  dispatch,
}) => {
  const [isSelecting, setSelecting] = useState(action === undefined);
  const [selector, setSelector] = useState(action?.config.selector ?? "");
  const [durationInput, setDurationInput] = useState("");
  const [selectedInterval, setSelectedInterval] = useState(
    intervalOptions[0].value,
  );
  const isDurationValid = useMemo(
    () => !isNaN(parseInt(durationInput)),
    [durationInput],
  );

  useEffect(() => {
    dispatch({
      type: ActionMessageType.ToggleElementSelection,
      payload: action === undefined,
    });
  }, []);

  useEffect(() => {
    if (!action) {
      return;
    }

    if (action.config.interval) {
      const match = action.config.interval.match(/^([1-9]\d*)([smh])$/)!;
      setDurationInput(match[1]);
      setSelectedInterval(match[2]);
    }

    if (action.config.selector) {
      setSelecting(false);
      setSelector(action.config.selector);
    }
  }, [action]);

  return (
    <ActionEditorContainer>
      <h2>Take snapshots for duration</h2>
      {isSelecting ? (
        <>
          <p>Click on the element that you want to take snapshots of.</p>
          <Button
            fullWidth
            mini
            onClick={() => {
              setSelecting(false);
              dispatch({
                type: ActionMessageType.ToggleElementSelection,
                payload: false,
              });
            }}
            className="editor-button"
          >
            <FormattedMessage
              id="ui_component.site.recorder.select_page"
              defaultMessage="Select the entire page"
            />
          </Button>
        </>
      ) : (
        <>
          <div className="editor-field">
            <div className="field-info">
              <div className="field-name">Selected element</div>
              <Button
                mini
                onClick={() => {
                  setSelecting(true);
                  setSelector("");
                  dispatch({
                    type: ActionMessageType.ToggleElementSelection,
                    payload: true,
                  });
                }}
                className="editor-inline-button"
              >
                <FormattedMessage
                  id="common.text.change"
                  defaultMessage="Change"
                />
              </Button>
            </div>
            <TextArea
              value={selector.length > 0 ? selector : "Entire page"}
              disabled
            />
          </div>
          <div className="editor-field">
            <div className="field-info">
              <div className="field-name">Duration</div>
            </div>
            <Input
              labelPosition="right"
              label={
                <Dropdown
                  options={intervalOptions}
                  value={selectedInterval}
                  onChange={(e, data) =>
                    setSelectedInterval(data.value as string)
                  }
                />
              }
              placeholder="5"
              value={durationInput}
              type="number"
              min="1"
              onChange={(event) => setDurationInput(event.target.value)}
              fluid
            />
          </div>
        </>
      )}
      <div className="action-button-container">
        <PrimaryButton
          fullWidth
          mini
          onClick={() => {
            const interval = `${parseInt(durationInput)}${selectedInterval}`;

            if (action) {
              action.config.interval = interval;

              if (selector.length === 0) {
                action.name = "Take snapshots of the page";
                action.config.url = "";
                action.config.element = undefined;
                action.config.selector = undefined;
              }
            } else {
              dispatch({
                type: ActionMessageType.UpdateActionEditor,
                payload: {
                  action: new RenderDurationAction(
                    "Take snapshots of the page",
                    {
                      url: "",
                      element: undefined,
                      selector: undefined,
                      interval,
                    },
                  ),
                },
              });
            }

            dispatch({ type: ActionMessageType.SaveActionEditor });
          }}
          className="editor-button"
          disabled={
            isSelecting || durationInput.length === 0 || !isDurationValid
          }
        >
          <FormattedMessage id="common.text.save" defaultMessage="Save" />
        </PrimaryButton>
        <Button
          fullWidth
          mini
          onClick={() =>
            dispatch({ type: ActionMessageType.CloseActionEditor })
          }
          className="editor-button"
        >
          <FormattedMessage
            id="ui_component.common.label.cancel"
            defaultMessage="Cancel"
          />
        </Button>
      </div>
    </ActionEditorContainer>
  );
};

const DelayTimeEditor: FunctionComponent<{
  dispatch: DispatchFunction;
  action?: DelayDurationAction;
}> = ({ dispatch, action }) => {
  const [delayInput, setDelayInput] = useState("");
  const [selectedInterval, setSelectedInterval] = useState(
    intervalOptions[0].value,
  );
  const isDelayValid = useMemo(
    () => !isNaN(parseInt(delayInput)),
    [delayInput],
  );

  useEffect(() => {
    if (!action) {
      return;
    }

    const match = action.config.interval.match(/^([1-9]\d*)([smh])$/)!;
    setDelayInput(match[1]);
    setSelectedInterval(match[2]);
  }, [action]);

  return (
    <ActionEditorContainer>
      <h2>Delay for duration</h2>
      <div className="editor-field">
        <div className="field-info">
          <div className="field-name">Delay for...</div>
        </div>
        <Input
          labelPosition="right"
          label={
            <Dropdown
              options={intervalOptions}
              value={selectedInterval}
              onChange={(e, data) => setSelectedInterval(data.value as string)}
            />
          }
          placeholder="5"
          value={delayInput}
          type="number"
          min="1"
          onChange={(event) => setDelayInput(event.target.value)}
          fluid
        />
      </div>
      <div className="action-button-container">
        <PrimaryButton
          fullWidth
          mini
          onClick={() => {
            const interval = `${parseInt(delayInput)}${selectedInterval}`;

            if (action) {
              action.config = { interval };
            } else {
              dispatch({
                type: ActionMessageType.UpdateActionEditor,
                payload: {
                  action: new DelayDurationAction("Delay", { interval }),
                },
              });
            }

            dispatch({ type: ActionMessageType.SaveActionEditor });
          }}
          className="editor-button"
          disabled={delayInput.length === 0 || !isDelayValid}
        >
          <FormattedMessage id="common.text.save" defaultMessage="Save" />
        </PrimaryButton>
        <Button
          fullWidth
          mini
          onClick={() =>
            dispatch({ type: ActionMessageType.CloseActionEditor })
          }
          className="editor-button"
        >
          <FormattedMessage
            id="ui_component.common.label.cancel"
            defaultMessage="Cancel"
          />
        </Button>
      </div>
    </ActionEditorContainer>
  );
};

const ScrollEditor: FunctionComponent<{
  dispatch: DispatchFunction;
  action?: ScrollAction;
}> = ({ dispatch, action }) => {
  const [xInput, setXInput] = useState(String(action?.config.x ?? ""));
  const [yInput, setYInput] = useState(String(action?.config.y ?? ""));

  const isXValid = useMemo(() => !isNaN(parseInt(xInput)), [xInput]);
  const isYValid = useMemo(() => !isNaN(parseInt(yInput)), [yInput]);

  return (
    <ActionEditorContainer>
      <h2>Scroll the page</h2>
      <div className="editor-field">
        <div className="field-info">
          <div className="field-name">Vertical scroll (pixels)</div>
        </div>
        <Input
          value={yInput}
          type="number"
          onChange={(event) => setYInput(event.target.value)}
          error={yInput.length > 0 && !isYValid}
          fluid
        />
      </div>
      <div className="editor-field">
        <div className="field-info">
          <div className="field-name">Horizontal scroll (pixels)</div>
        </div>
        <Input
          value={xInput}
          type="number"
          onChange={(event) => setXInput(event.target.value)}
          error={xInput.length > 0 && !isXValid}
          fluid
        />
      </div>
      <div className="action-button-container">
        <PrimaryButton
          fullWidth
          mini
          onClick={() => {
            let x = 0;
            let y = 0;

            if (isXValid) {
              x = parseInt(xInput);
            }

            if (isYValid) {
              y = parseInt(yInput);
            }

            if (action) {
              action.config = { x, y };
            } else {
              dispatch({
                type: ActionMessageType.UpdateActionEditor,
                payload: {
                  action: new ScrollAction("Scroll", { x, y }),
                },
              });
            }

            dispatch({ type: ActionMessageType.SaveActionEditor });
          }}
          className="editor-button"
          disabled={
            (xInput.length === 0 && yInput.length === 0) ||
            (xInput.length > 0 && !isXValid) ||
            (yInput.length > 0 && !isYValid)
          }
        >
          <FormattedMessage id="common.text.save" defaultMessage="Save" />
        </PrimaryButton>
        <Button
          fullWidth
          mini
          onClick={() =>
            dispatch({ type: ActionMessageType.CloseActionEditor })
          }
          className="editor-button"
        >
          <FormattedMessage
            id="ui_component.common.label.cancel"
            defaultMessage="Cancel"
          />
        </Button>
      </div>
    </ActionEditorContainer>
  );
};
