import { useCallback, useEffect, useReducer } from "react";
import { FormattedMessage } from "react-intl";
import dashboardsEmptyState from "../../../images/dashboards-empty-state.svg";
import { Maybe, Site } from "../../../types.g";
import {
  SessionKeepAliveAction,
  SiteRecorderAction,
  SiteRecorderActionType,
} from "./actions/models";
import { ActionsControl } from "./actionscontrol";
import Layout from "./layout";
import { NavigationControl } from "./navigationcontrol";
import {
  ActionMessageType,
  ActionName,
  getSessionAction,
  InitialRecorderState,
  RecorderReducer,
} from "./reducers";
import { SiteFrame } from "./siteframe";
import { DashboardsEmptyState } from "./styles";
import { SiteRecorderActionMode } from "../index";
import { SessionKeepAliveEditor } from "./actions/editor";
import { useAppContext } from "src/hooks/useAppContext";
import { ModalSize } from "@screencloud/screencloud-ui-components";

export interface SiteRecorderProps {
  onSiteConfigurationClick: () => void;
  site?: Maybe<Partial<Site>>;
  onActionsUpdated: (actions: SiteRecorderAction[]) => void;
  handleSetupFinished: (setupFinished: boolean) => void;
  setDashboardTitle: (string) => void;
  dashboardTitle: string;
  setExtensionVersion: (string) => void;
  isChangesToSave: boolean;
  actionMode: SiteRecorderActionMode;
}

export const SiteRecorder = (props: SiteRecorderProps) => {
  const context = useAppContext();
  /**
   * when adding a new Dashboard after potentially an edit Dashboard action
   * force the intial action session refresh value to be default
   */
  if (!props.site) {
    InitialRecorderState.actions = [
      new SessionKeepAliveAction(ActionName.MANAGE_SESSION, {
        interval: undefined, // this is the default value for the interval so we know user hasnt set it
      }),
    ];
  }

  /**
   * If site exists (we are editing a Dashboard) replace the initial state
   * session keep alive with the actual saved value.
   */
  if (
    props.site &&
    props.site.actions[0].type === SiteRecorderActionType.SessionKeepAlive
  ) {
    InitialRecorderState.actions = [props.site.actions[0]];
  }

  const [state, dispatch] = useReducer(RecorderReducer, {
    ...InitialRecorderState,
    url: props.site?.url || "",
    dashboardTitle: props.dashboardTitle,
  });

  useEffect(() => {
    props.onActionsUpdated(state.actions);
  }, [state.actions]);

  function handleRecordingChange(isRecording: boolean) {
    // if the recording has stopped and the session interval has not been set and the journey has finished
    // then we need to show the user the session keep alive editor for them to set the interval
    if (
      !isRecording &&
      !state.hasSessionIntervalSet &&
      state.hasRecordingFinished
    ) {
      const sessionAction = getSessionAction(state.actions);
      dispatch({
        type: ActionMessageType.UpdateActionEditor,
        payload: sessionAction,
      });

      context.modal.openModal(
        <SessionKeepAliveEditor action={sessionAction} dispatch={dispatch} />,
        "",
        {
          opts: {
            disableTitle: true,
            size: ModalSize.SMALL,
            disableCloseButton: true,
          },
        },
      );
    }
  }

  function handleSetupFinished(setupFinished: boolean) {
    props.handleSetupFinished(setupFinished);
  }

  useEffect(() => {
    handleSetupFinished(
      !!state.hasRecordingFinished && !!state.hasSessionIntervalSet,
    );
  }, [state.hasRecordingFinished, state.hasSessionIntervalSet]);

  useEffect(() => {
    handleRecordingChange(state.isRecording);
  }, [state.isRecording]);

  useEffect(() => {
    // Update to take locale into account
    const defaultDashboardTitle = "Dashboard";
    if (props.dashboardTitle === defaultDashboardTitle) {
      props.setDashboardTitle(state.dashboardTitle);
    }
  }, [state.dashboardTitle]);

  useEffect(() => {
    props.setExtensionVersion(state.extensionVersion);
  }, [state.extensionVersion]);

  const navigationHandler = useCallback(() => {
    console.log("navigated");
  }, [state]);

  return (
    <Layout>
      <Layout.Navigator>
        <NavigationControl
          url={state.url}
          recorderUrl={state.recorderUrl}
          mode={state.navigationMode}
          frameWidth={state.frameWidthType}
          dispatch={dispatch}
          onNavigate={navigationHandler}
          isRecording={state.isRecording}
          hasActions={state.actions.length > 1} // Manage Session will now always be present, meaning state.actions will always have at least 1 action, so we need to check if there are any additional actions
          isShowHints={!props.site}
          actionMode={props.actionMode}
        />
      </Layout.Navigator>
      <Layout.Controls visible={state.url}>
        <ActionsControl
          actions={state.actions}
          isSelectingElement={state.isSelectingElement}
          isRecording={state.isRecording}
          selectedElement={state.selectedElement}
          editorType={state.editorType}
          editorAction={state.editorAction}
          dispatch={dispatch}
          isChangesToSave={props.isChangesToSave}
        />
      </Layout.Controls>
      <Layout.Pane visible={state.url}>
        <SiteFrame
          url={state.url}
          mode={state.navigationMode}
          isRecording={state.isRecording}
          isSelectingElement={state.isSelectingElement}
          selectedElement={state.selectedElement}
          editorType={state.editorType}
          editorAction={state.editorAction}
          dispatch={dispatch}
          actions={state.actions}
          hasOtc={state.hasOtc}
          navigateCount={state.navigateCount}
          site={props.site}
          isChangesToSave={props.isChangesToSave}
          actionMode={props.actionMode}
        />
      </Layout.Pane>
      {state.actions.length <= 1 && !state.url && (
        <DashboardsEmptyState>
          <img src={dashboardsEmptyState} />
          <div className="tips">
            <h2>💡 Protips</h2>
            <h3>
              <FormattedMessage
                id="ui_component.site.recorder.pro_tip_1"
                defaultMessage="Always use the final preview link, not the homepage or login page. This bypasses potential changes in the dashboard platform, preventing disruptions to your action flow."
              />
            </h3>
          </div>
        </DashboardsEmptyState>
      )}
    </Layout>
  );
};
