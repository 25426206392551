import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useAppContext } from "src/hooks/useAppContext";

interface UseShareConfirmActionProps {
  defaultShareOption: string;
}

interface UseShareConfirmActionReturn {
  resetPermissionConfirmAction: (onConfirm: () => void) => void;
  unshareEditableChannelConfirmAction: (onConfirm: () => void) => void;
}

const useShareConfirmAction = ({
  defaultShareOption,
}: UseShareConfirmActionProps): UseShareConfirmActionReturn => {
  const context = useAppContext();
  const [shareOption, setshareOption] = useState("");

  useEffect(() => {
    setshareOption(defaultShareOption);
  }, [defaultShareOption]);

  const resetPermissionConfirmAction = async (onConfirm: () => void) => {
    const { confirm } = await context.modal.confirm(
      <div className="reset-permissions-modal">
        <FormattedMessage
          id="ui_component.common.label.reset_permissions_confirmation"
          defaultMessage="All spaces permission will be restored to '{permissionType}'?"
          values={{ permissionType: shareOption }}
        />
        <p>
          <FormattedMessage
            id="ui_component.common.label.confirm.reset_permissions.additional_text"
            defaultMessage="This Action Cannot be undone."
          />
        </p>
      </div>,
      {
        confirm: (
          <FormattedMessage
            id="ui_component.common.label.confirm"
            defaultMessage="Confirm"
          />
        ),
        isDanger: false,
      },
    );

    if (confirm) {
      onConfirm?.();
    }
  };

  const unshareEditableChannelConfirmAction = async (onConfirm: () => void) => {
    const { confirm } = await context.modal.confirm(
      <div className="reset-permissions-modal">
        <FormattedMessage
          id="ui_component.common.label.unshare_editable_channel_confirmation"
          defaultMessage="Warning: Local Content Will Be Lost"
        />
        <p>
          <FormattedMessage
            id="ui_component.common.label.confirm.unshare_editable_channel.additional_text"
            defaultMessage="Unsharing this channel will delete all locally added content. You’ll need to re-add it if you share the channel again. Are you sure you want to unshare this channel?"
          />
        </p>
      </div>,
      {
        confirm: (
          <FormattedMessage
            id="ui_component.common.label.unshare_channel"
            defaultMessage="Unshare Channel"
          />
        ),
        isDanger: false,
      },
    );

    if (confirm) {
      onConfirm?.();
    }
  };

  return { resetPermissionConfirmAction, unshareEditableChannelConfirmAction };
};

export default useShareConfirmAction;
