import * as React from "react";
import {
  failedProcessingThumbnail,
  getMediaPlaceHolder,
  getPreviewImageURLFromFile,
  imagePlaceholder,
  isAudio,
  MediaInput,
} from "../../helpers/mediaHelper";
import { JobStatus } from "../../types.g";
import { Styled } from "./styles";
import { ResizingMode } from "src/constants/mediaTypes";

export interface ThumbnailProps {
  overlay?: React.ReactNode;
  media: MediaInput;
  width?: number;
  height?: number;
  className?: string;
  secureMediaPolicy?: string | undefined;
}

export interface ThumbnailState {
  src: string;
}

export default class Thumbnail extends React.Component<
  ThumbnailProps,
  ThumbnailState
> {
  public static defaultProps = {
    height: 180,
    width: 320,
  };

  private readonly imgRef: React.RefObject<HTMLImageElement>;
  private readonly placeholder: string;

  constructor(props: ThumbnailProps) {
    super(props);

    this.imgRef = React.createRef();
    this.placeholder = getMediaPlaceHolder(props.media);

    this.state = {
      src: this.placeholder,
    };
  }

  public componentDidMount() {
    this.getAndSetPreviewImage();
  }
  public componentDidUpdate(
    prevProps: Readonly<ThumbnailProps>,
    prevState: Readonly<ThumbnailState>,
    snapshot?: any,
  ): void {
    this.getAndSetPreviewImage();
  }

  public render(): JSX.Element {
    const { overlay, className } = this.props;
    return (
      <Styled className="thumbnail">
        <div className="wrapper">
          <img
            ref={this.imgRef}
            src={this.state.src || ""}
            className={className}
          />
          {overlay && <div className="overlay">{overlay}</div>}
        </div>
      </Styled>
    );
  }

  private getAndSetPreviewImage = () => {
    const { media, width, height } = this.props;
    if (media?.fileProcessingStatus === JobStatus.Failed) {
      return this.setSrc(failedProcessingThumbnail);
    } else if (isAudio(media)) {
      return this.setSrc(getMediaPlaceHolder(media));
    } else if (!media || !media.fileProcessingStatus || !media.mimetype) {
      return this.setSrc(imagePlaceholder);
    }

    const imageURL: string | string[] = getPreviewImageURLFromFile(
      media,
      width,
      height,
      this.props.secureMediaPolicy,
      ResizingMode.Fill, // for thumbnail use fill mode
    );
    if (imageURL != null && typeof imageURL === "string") {
      this.setSrc(imageURL);
    } else if (imageURL != null && typeof imageURL === "object") {
      if (imageURL.length > 0) {
        this.setSrc(imageURL[0]);
      }
    }
  };

  private setSrc(source: string) {
    const img = new Image();
    img.src = source;
    img.onload = () => {
      if (this.imgRef.current) {
        this.imgRef.current.src = source;
      }
    };
    img.onerror = () => {
      if (this.imgRef.current) {
        this.imgRef.current.src = this.placeholder;
      }
    };
  }
}
