import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Dropdown, Search } from "@screencloud/screencloud-ui-components";
import {
  canResetPermissions,
  getAllSpaces,
  getChannelShareOptions,
  getShareAllValue,
  getDropdownValueForSpaceItem,
  getNonOwnerSpaces,
  getOwnerSpace,
  getShareAllText,
  SharingMode,
} from "../helper";
import { useAppContext } from "src/hooks/useAppContext";
import { Shareable } from "src/helpers/shareableHelper";
import { ChannelShareItem } from "./ChannelShareItem";
import { ResetPermissions } from "./ResetPermissions";
import { useShareChannelToSpace } from "../hooks/useShareChannelToSpace";
import { useShareChannelToAllSpaces } from "../hooks/useShareChannelToAllSpaces";
import {
  SharedSpaceWithSharingMode,
  useSharedSpacesByChannelIdQuery,
} from "src/types.g";
import useShareConfirmAction from "../hooks/useShareConfirmAction";

export interface ChannelShareSpaceListProps {
  shareable: Shareable;
  isDisabled?: boolean;
}

const ChannelShareList = ({
  isDisabled,
  shareable,
}: ChannelShareSpaceListProps) => {
  const context = useAppContext();
  const allSpaces = getAllSpaces(context);
  const [shareAll, setShareAll] = useState(
    getShareAllValue(shareable) as SharingMode,
  );
  const shareChannelToSpace = useShareChannelToSpace();
  const [sharedSpaces, setSharedSpaces] = useState<
    SharedSpaceWithSharingMode[]
  >([]);
  const { shareChannelToAllSpaces, loading } = useShareChannelToAllSpaces();
  const { unshareEditableChannelConfirmAction } = useShareConfirmAction({
    defaultShareOption: getShareAllText(shareable, shareAll),
  });
  const [searchQuery, setsearchQuery] = useState("");
  const [nonOwnerSpaces, setNonOwnerSpaces] = useState(
    getNonOwnerSpaces(context, shareable, searchQuery, sharedSpaces),
  );

  const ownerSpace = getOwnerSpace(context, shareable, searchQuery);

  const handelSearchChange = (event: React.SyntheticEvent<any>, data: any) => {
    setsearchQuery(data.value);
    setNonOwnerSpaces(
      getNonOwnerSpaces(context, shareable, data.value, sharedSpaces),
    );
  };

  const clearSearchQuery = () => {
    setsearchQuery("");
    setNonOwnerSpaces(getNonOwnerSpaces(context, shareable, "", sharedSpaces));
  };

  const { loading: isSharedSpaceLoading } = useSharedSpacesByChannelIdQuery({
    variables: {
      id: shareable.id,
    },
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setSharedSpaces(
        data?.channelById?.sharedSpacesWithSharingModeByChannelId
          ?.nodes as SharedSpaceWithSharingMode[],
      );
    },
  });

  useEffect(() => {
    setNonOwnerSpaces(
      getNonOwnerSpaces(context, shareable, searchQuery, sharedSpaces),
    );
  }, [sharedSpaces, searchQuery]);

  const handleItemChange = (
    selectedSpaceId: string,
    sharedStatus: SharingMode,
  ) => {
    setNonOwnerSpaces(
      nonOwnerSpaces.map((space) => {
        if (space.id === selectedSpaceId) {
          return {
            ...space,
            sharingMode: sharedStatus,
          };
        }
        return space;
      }),
    );
    return shareChannelToSpace(shareable.id, selectedSpaceId, sharedStatus);
  };

  const handleAllChange = (
    event: React.SyntheticEvent<any>,
    value: SharingMode,
  ) => {
    setShareAll(value);
    setNonOwnerSpaces(
      getNonOwnerSpaces(context, shareable, searchQuery, sharedSpaces).map(
        (space) => ({
          ...space,
          sharingMode: value,
        }),
      ),
    );
    shareChannelToAllSpaces(shareable.id, value);
  };

  const handleShareWithConfirmation = (
    callback: () => void,
    requiresConfirmation: boolean,
  ) => {
    if (requiresConfirmation) {
      unshareEditableChannelConfirmAction(callback);
    } else {
      callback();
    }
  };

  const handleResetPermissions = () => {
    setNonOwnerSpaces(
      getNonOwnerSpaces(context, shareable, searchQuery, sharedSpaces).map(
        (space) => ({
          ...space,
          sharingMode: shareAll,
        }),
      ),
    );
    shareChannelToAllSpaces(shareable.id, shareAll);
  };

  const allowShareAll = context.currentPermissions.validateOrg(
    "channel",
    "share",
  );

  return (
    <>
      {allSpaces.length > 1 && (
        <div className="shared-space-list">
          {allowShareAll && (
            <>
              <div className="share-all">
                <div className="column">
                  <h3>
                    <FormattedMessage
                      id="common.label.share_all_channels"
                      defaultMessage="Share to all spaces"
                    />
                  </h3>
                  <p className="sub-heading">
                    <FormattedMessage
                      id="channels.shared_all_description"
                      defaultMessage="Set default sharing permission to either allow all spaces to use this channel only or to allow local content to be added"
                    />
                  </p>
                </div>
                <Dropdown
                  options={getChannelShareOptions()}
                  selection
                  onChange={(event, data) => {
                    handleShareWithConfirmation(
                      () => handleAllChange(event, data.value as SharingMode),
                      shareAll === SharingMode.Editable,
                    );
                  }}
                  value={shareAll}
                  disabled={isDisabled}
                />
              </div>

              <div className="divider" />
            </>
          )}
          <div className="custom">
            <ResetPermissions
              defaultShareOption={getShareAllText(shareable, shareAll)}
              canResetPermissions={canResetPermissions(
                nonOwnerSpaces
                  .map((space) => space.sharingMode)
                  .filter(Boolean) as SharingMode[],
                shareAll,
              )}
              onResetPermissions={handleResetPermissions}
              isDisabled={isDisabled || loading}
            />
            <div className="row search-space">
              <Search
                data-testid="search-space"
                placeholder="Search"
                value={searchQuery}
                onChange={handelSearchChange}
                onClear={clearSearchQuery}
                showNoResults={false}
                inline
              />
            </div>

            {ownerSpace && (
              <ChannelShareItem
                key={`share-item-${ownerSpace.name}`}
                name={ownerSpace.name}
                id={ownerSpace.id}
                isDisabled={true}
                isOwner={true}
                isLoading={isSharedSpaceLoading}
              />
            )}
            {nonOwnerSpaces.map((space) => {
              const defaultValue =
                space.sharingMode ||
                getDropdownValueForSpaceItem(sharedSpaces, space.id);
              return (
                <ChannelShareItem
                  key={`share-item-${space.name}`}
                  name={space.name}
                  id={space.id}
                  isOwner={false}
                  onItemChange={(
                    selectedSpaceId: string,
                    sharedStatus: SharingMode,
                  ) => {
                    handleShareWithConfirmation(
                      () => handleItemChange(selectedSpaceId, sharedStatus),
                      defaultValue === SharingMode.Editable,
                    );
                  }}
                  value={defaultValue}
                  isDisabled={isDisabled}
                  isLoading={isSharedSpaceLoading}
                />
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export { ChannelShareList };
