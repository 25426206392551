import { ModalSize } from "@screencloud/screencloud-ui-components";
import { FunctionComponent, useState } from "react";
import withActivateBanner, { WithActivateBanner } from "./withActiveBanner";
import { useAppContext } from "src/hooks/useAppContext";
import { TrailExpiredModal } from "./TrialExpiredModal";

interface Props {
  isFullWidth?: boolean;
}

const ActivateBanner: FunctionComponent<Props & WithActivateBanner> = ({
  isShowActivateBanner,
  isFullWidth,
  remainingTrialDays,
  onClickActivateBanner,
  planId,
}) => {
  const context = useAppContext();
  const [verifyClosed, setVerifyClosed] = useState(false);

  // for testing purpose
  const isForceTrialEnd = localStorage.getItem("isTrialEnd") === "true";
  const isTrialEnd = isForceTrialEnd || (remainingTrialDays ?? 0) <= 0;

  const isCorePlan = planId?.includes("core");

  const onGetStartedCallback = (periodUnit: string) => {
    setVerifyClosed(true);
    context.modal.closeModals();
    context.history.push(`/billing/latest/manage?periodUnit=${periodUnit}`);
  };

  const isPlanIdValid = (planId: string | null): boolean => {
    // ex: core-year-gbp
    if (!planId || !planId.includes("-")) {
      return false;
    }
    const planType = planId.split("-")[0];
    return !!(planType === "core" || planType === "pro");
  };

  const renderTrialEndBanner = () => {
    if (isPlanIdValid(planId) && !verifyClosed) {
      context.modal.openModal(
        <TrailExpiredModal
          planId={planId}
          onGetStartedCallback={(periodUnit: string) =>
            onGetStartedCallback(periodUnit)
          }
        />,
        null,
        {
          opts: {
            disableTitle: true,
            closeOnDimmerClick: false,
            size: ModalSize.SMALL,
            closeButtonId: `close-${
              isCorePlan ? "core" : "pro"
            }-trial-expired-modal`,
          },
        },
      );
    }
    return <div />;
  };

  if (isShowActivateBanner && isTrialEnd) {
    return renderTrialEndBanner();
  }
  return <div />;
};

export { ActivateBanner };
export default withActivateBanner(ActivateBanner) as React.ComponentType<Props>;
