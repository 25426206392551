import * as React from "react";
import MediaPickerContainerComponent from "./container";
import { BrowserRouterProps } from "react-router-dom";
import { AppInstance, File, Link, Maybe, Playlist, Site } from "../../types.g";

import { UUID } from "@screencloud/uuid";
import { RefType } from "../../constants/constants";
import { ScreenPickerActions } from "../ScreenPicker";
import { MediaPickerActionMode } from "./media";

export interface MediasComponentProps {
  handleSelectedPickerItem: (screenPickerAction: ScreenPickerActions) => void;
  callBack: (
    mediaId: string[],
    mediaType: RefType,
    data:
      | Partial<File>[]
      | Partial<Playlist>[]
      | Maybe<Partial<Link>>[]
      | Maybe<Partial<Site>>[]
      | Partial<AppInstance>[],
    expiresAt?: Date,
  ) => void;
  action: MediaPickerActionMode;
  allowMediaMimeType?: string[];
  menu?: RefType[];
  multiple?: boolean;
  selected?: string;
  section?: RefType;
  router?: BrowserRouterProps;
  screenPickerAction: ScreenPickerActions;
  screenId?: UUID;
  spaceId?: UUID;
}

class MediasComponent extends React.PureComponent<MediasComponentProps, {}> {
  constructor(props: MediasComponentProps) {
    super(props);
  }
  public render() {
    const {
      action,
      allowMediaMimeType,
      callBack,
      menu,
      multiple,
      screenId,
      selected,
      section,
      screenPickerAction,
      spaceId,
      router,
      handleSelectedPickerItem,
    } = this.props;

    return (
      <MediaPickerContainerComponent
        screenId={screenId}
        action={action}
        allowMediaMimeType={allowMediaMimeType}
        menu={menu as RefType[]}
        multiple={multiple}
        callBack={callBack}
        screenPickerAction={screenPickerAction}
        selected={selected}
        section={section || RefType.CHANNEL}
        router={router}
        spaceId={spaceId}
        handleSelectedPickerItem={handleSelectedPickerItem}
      />
    );
  }
}

export default MediasComponent as React.ComponentType<MediasComponentProps>;
