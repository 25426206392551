import { Icon, Search } from "@screencloud/screencloud-ui-components";
import { UUID } from "@screencloud/uuid";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { AppContextType } from "src/AppContextProvider/type";
import { AppContext } from "../../AppContextProvider/AppContext";
import { SortingActions } from "../../constants/constants";
import { FEATURE_FLAGS_ENUM } from "../../constants/featureFlag";
import { getChannelCover } from "../../helpers/channelHelper";
import { Channel, Maybe } from "../../types.g";
import EmptyState from "../EmptyState";
import { ApolloProps, withData } from "./apollo";
import ChannelPickerItem from "./item";
import SearchChannelComponent from "./search";
import { Styled } from "./styles";

export interface ChannelPickerProps {
  spaceId?: UUID;
  callback?: (channelId: string, channel: Maybe<Partial<Channel>>) => void;
}

export interface ChannelPickerState {
  isOrderByAscending: boolean;
  channelCount: number;
  searchString: string;
  selectedChannel: string;
  selectedData: Maybe<Partial<Channel>>;
  sortBy: SortingActions;
}
class ChannelPickerComponent extends React.PureComponent<
  ChannelPickerProps & ApolloProps,
  ChannelPickerState
> {
  public static contextType = AppContext;
  public context: AppContextType;
  constructor(props: ChannelPickerProps & ApolloProps) {
    super(props);
    this.state = {
      isOrderByAscending: true,
      channelCount: 0,
      searchString: "",
      selectedChannel: "",
      selectedData: {},
      sortBy: SortingActions.SORT_BY_NAME,
    };
  }

  public onClearSearchInput = () => {
    this.setState({ searchString: "" });
  };

  public toggleSelect = (id, channel) => {
    if (this.props.callback) {
      this.props.callback(id, channel);
    }

    this.setState({
      selectedChannel: id,
      selectedData: channel,
    });
  };

  public isSelectedItemsEmpty() {
    return this.state.selectedChannel === "";
  }

  public toggleSortAndOrderMediaItem = (action: SortingActions) => {
    if (this.state.sortBy === action) {
      this.setState((prevState: ChannelPickerState) => ({
        ...prevState,
        isOrderByAscending: !prevState.isOrderByAscending,
        sortBy: action,
      }));
    } else {
      this.setState({ isOrderByAscending: true, sortBy: action });
    }
  };

  public renderOrderCaretIcon = (): JSX.Element => {
    const orderIcon = this.state.isOrderByAscending ? "caret-down" : "caret-up";
    return <Icon className="caret-order" name={orderIcon} />;
  };

  public render() {
    const {
      channelListData,
      searchString,
      updateSearchString,
      clearSearch,
      isSearch,
      query,
    } = this.props;
    const channels = channelListData.channels;

    return (
      <Styled className="media-content channel">
        <div className="content-header">
          <Search
            className="search"
            onClear={clearSearch}
            onChange={(_, data) => updateSearchString(data.value)}
            placeholder="Search Channel"
            showNoResults={false}
            value={searchString ?? ""}
          />
        </div>

        <div className="container">
          {channelListData.loading ? null : (
            <>
              <div className="media-item-header">
                <div className="media-core">
                  <div
                    className="media-alpha"
                    onClick={() =>
                      this.toggleSortAndOrderMediaItem(
                        SortingActions.SORT_BY_NAME,
                      )
                    }
                  >
                    <FormattedMessage
                      id="subheader.label.name"
                      defaultMessage="Name"
                    />
                    {this.state.sortBy === SortingActions.SORT_BY_NAME &&
                      this.renderOrderCaretIcon()}
                  </div>
                  <div className="media-layout">
                    <FormattedMessage
                      id="subheader.label.layout"
                      defaultMessage="Layout"
                    />
                  </div>
                  {this.context.shouldShowFeature(
                    FEATURE_FLAGS_ENUM.CASTING,
                  ) && (
                    <div className="media-channel-screens">
                      <FormattedMessage
                        id="subheader.label.casting"
                        defaultMessage="Casting"
                      />
                    </div>
                  )}
                </div>
              </div>
              {channels.length ? (
                <div className="layout-list" id="scrollableDiv">
                  {isSearch ? (
                    <SearchChannelComponent
                      searchString={query}
                      spaceId={this.props.spaceId}
                      callback={this.props.callback}
                    />
                  ) : (
                    <>
                      {channels.map((channel, index) => {
                        if (channel) {
                          const coverData = getChannelCover(
                            channel as Channel,
                            this.context.secureMediaPolicy,
                          );
                          const isDraftPublished = channel.draft
                            ? channel.draft.isPublished!
                            : false;
                          const isChannelOwner =
                            this.context.user.settings.spaceId ===
                            channel.spaceId;
                          return (
                            channel !== null && (
                              <ChannelPickerItem
                                selected={
                                  channel.id === this.state.selectedChannel
                                }
                                key={`channel-item-${channel.id}-${Math.random()}`}
                                background={coverData}
                                channel={channel}
                                callBack={this.toggleSelect}
                                spaceExist={
                                  (this.context.allSpaces?.length ?? 0) > 1
                                }
                                isChannelOwner={isChannelOwner}
                                isPublish={isDraftPublished}
                                index={index}
                              />
                            )
                          );
                        } else {
                          return null;
                        }
                      })}
                      {this.props.channelListData.renderFetchMoreButton}
                    </>
                  )}
                </div>
              ) : (
                channelListData.isFirstTimeAlreadyLoaded &&
                channels.length === 0 && (
                  <EmptyState
                    section="playlist-picker"
                    cover={false}
                    className="empty"
                  >
                    <h3>
                      <FormattedMessage
                        id="channels.empty.empty_text"
                        defaultMessage="No channels added yet"
                      />
                    </h3>
                    <p>
                      <FormattedMessage
                        id="channels.empty.help_text"
                        defaultMessage="Create a channel to add to your screen"
                      />
                    </p>
                  </EmptyState>
                )
              )}
            </>
          )}
        </div>
      </Styled>
    );
  }
}

export default withData(
  ChannelPickerComponent,
) as React.ComponentType<ChannelPickerProps>;
