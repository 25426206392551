import { Viewport } from "@screencloud/secure-sites-extension-types/dist/utils";
import { genUuid } from "@screencloud/uuid";

export enum SiteRecorderActionType {
  Click = "browser/click",
  ClickNewTab = "browser/click/newtab",
  SelectOption = "browser/select/option",
  DelayDuration = "renderer/delay",
  DelayElement = "browser/delay",
  EnterApiKey = "browser/send/apikey",
  EnterOneTimeCode = "browser/send/otc",
  EnterPassword = "browser/send/password",
  EnterText = "browser/send/text",
  EnterUsername = "browser/send/username",
  Hover = "browser/hover",
  KeyPress = "browser/send/key",
  Loop = "renderer/loop",
  Navigate = "browser/navigate",
  RenderDuration = "renderer/render/duration",
  RenderSingle = "renderer/render/single",
  Scroll = "browser/scroll",
  SessionKeepAlive = "session/keep-alive",
}

export abstract class SiteRecorderAction {
  public readonly id: string;

  constructor(
    public type: SiteRecorderActionType,
    public name: string,
    public config: any,
  ) {
    this.id = genUuid();
  }
}

export interface IFrame {
  url: string;
  id?: string;
  ariaLabel?: string;
  rect?: DOMRect;
}

export interface ElementActionConfig {
  url: string;
  element?: string;
  selector?: string;
  shortSelector?: string;
  type?: string;
  id?: string;
  className?: string;
  credPairId?: string;
  ariaLabel?: string;
  placeholder?: string;
  value?: string;
  iframe?: IFrame;
  role?: string;
  a11yName?: string;
  nameAttr?: string;
}

export interface ClickActionConfig extends ElementActionConfig {
  button: number;
  text?: string;
  alt?: string;
  coordinates?: {
    x: number;
    y: number;
  };
  offsetCoordinates?: {
    x: number;
    y: number;
  };
}

export interface ElementSnapshotConfig extends ElementActionConfig {
  text?: string;
  rect?: DOMRect;
  viewport?: Viewport;
}

export class ClickAction extends SiteRecorderAction {
  constructor(
    name: string,
    public config: ClickActionConfig,
  ) {
    super(SiteRecorderActionType.Click, name, config);
  }
}

export class ClickNewTabAction extends SiteRecorderAction {
  constructor(
    name: string,
    public config: ClickActionConfig,
  ) {
    super(SiteRecorderActionType.ClickNewTab, name, config);
  }
}

export interface SelectOptionConfig extends ElementActionConfig {
  input: string;
  text: string;
}

export class SelectOptionAction extends SiteRecorderAction {
  constructor(
    name: string,
    public config: SelectOptionConfig,
  ) {
    super(SiteRecorderActionType.SelectOption, name, config);
  }
}

export interface DelayDurationConfig {
  interval: string;
}

export class DelayDurationAction extends SiteRecorderAction {
  constructor(
    name: string,
    public config: DelayDurationConfig,
  ) {
    super(SiteRecorderActionType.DelayDuration, name, config);
  }
}

export class DelayElementAction extends SiteRecorderAction {
  constructor(
    name: string,
    public config: ElementActionConfig,
  ) {
    super(SiteRecorderActionType.DelayElement, name, config);
  }
}

export interface EnterTextConfig extends ElementActionConfig {
  input: string;
  secretKey?: string;
  credentialName?: string;
}

export class EnterApiKeyAction extends SiteRecorderAction {
  public config: EnterTextConfig;

  constructor(name: string, config: EnterTextConfig) {
    super(SiteRecorderActionType.EnterApiKey, name, config);
  }
}

export class EnterOneTimeCodeAction extends SiteRecorderAction {
  constructor(
    name: string,
    public config: EnterTextConfig,
  ) {
    super(SiteRecorderActionType.EnterOneTimeCode, name, config);
  }
}

export class EnterPasswordAction extends SiteRecorderAction {
  constructor(
    name: string,
    public config: EnterTextConfig,
  ) {
    super(SiteRecorderActionType.EnterPassword, name, config);
  }
}

export class EnterTextAction extends SiteRecorderAction {
  constructor(
    name: string,
    public config: EnterTextConfig,
  ) {
    super(SiteRecorderActionType.EnterText, name, config);
  }
}

export class EnterUsernameAction extends SiteRecorderAction {
  constructor(
    name: string,
    public config: EnterTextConfig,
  ) {
    super(SiteRecorderActionType.EnterUsername, name, config);
  }
}

export class HoverAction extends SiteRecorderAction {
  constructor(
    name: string,
    public config: ElementActionConfig,
  ) {
    super(SiteRecorderActionType.Hover, name, config);
  }
}

export interface LoopConfig {
  loops: number;
  actions: SiteRecorderAction[];
}

export class LoopAction extends SiteRecorderAction {
  constructor(
    name: string,
    public config: LoopConfig,
  ) {
    super(SiteRecorderActionType.Loop, name, config);
  }
}

export interface NavigateConfig {
  url: string;
  acceptLanguage?: string;
}

export class NavigateAction extends SiteRecorderAction {
  constructor(
    name: string,
    public config: NavigateConfig,
  ) {
    super(SiteRecorderActionType.Navigate, name, config);
  }
}

export interface RenderDurationConfig extends ElementActionConfig {
  interval: string;
}

export class RenderDurationAction extends SiteRecorderAction {
  constructor(
    name: string,
    public config: RenderDurationConfig,
  ) {
    super(SiteRecorderActionType.RenderDuration, name, config);
  }
}

export class RenderSingleAction extends SiteRecorderAction {
  constructor(
    name: string,
    public config: ElementSnapshotConfig,
  ) {
    super(SiteRecorderActionType.RenderSingle, name, config);
  }
}

export interface SessionKeepAliveConfig {
  interval?: string;
  timezone?: string;
}

export class SessionKeepAliveAction extends SiteRecorderAction {
  constructor(
    name: string,
    public config: SessionKeepAliveConfig,
  ) {
    super(SiteRecorderActionType.SessionKeepAlive, name, config);
  }
}

export interface ScrollConfig {
  x: number;
  y: number;
}

export class ScrollAction extends SiteRecorderAction {
  constructor(
    name: string,
    public config: ScrollConfig,
  ) {
    super(SiteRecorderActionType.Scroll, name, config);
  }
}
