import { useState } from "react";
import { Checkbox, Icon } from "@screencloud/screencloud-ui-components";
import { PrimaryButton } from "src/helpers/whiteLabel";

import {
  ButtonContainer,
  Container,
  CoreContainer,
  FeatureContainer,
  Footer,
  PlanDescription,
  PlanTitle,
  PriceContainer,
  TitleContainer,
  ToggleContainer,
} from "./style";
import { useAppContext } from "src/hooks/useAppContext";
import { Constants } from "src/billinglatest/constants";
import { plans } from "./plans";
import { FormattedMessage } from "react-intl";
import { getContactUrl } from "src/helpers/onboardingHelper";

type Props = {
  onGetStartedCallback: (periodUnit: string) => void;
  planId: string | null;
};

export const TrailExpiredModal = (props: Props) => {
  const context = useAppContext();
  const { onGetStartedCallback, planId } = props;

  const currency = planId?.split("-")[2] ?? "gbp";

  // Extract the plan name from the planId. If the plan name is not "core" or "pro", default to "core".
  const planName = planId?.split("-")[0] ?? "core";

  // Check if plans object contains necessary data
  if (
    !plans[planName] ||
    !plans[planName].price ||
    !plans[planName].price.yearly ||
    !plans[planName].price.yearly[currency]
  ) {
    return null;
  }
  const isCorePlan = planName === "core";
  const [price, setPrice] = useState(
    plans[planName].price.yearly[currency].cost,
  );
  const [peroidUnit, setPeriodUnit] = useState<"month" | "year">("year");

  const firstName = context.currentUser?.givenName;
  const lastName = context.currentUser?.familyName;
  const email = context.currentUser?.email;
  const jobTitle = context.currentUser?.preferences.userInfo?.job?.title;
  const orgName = context.currentOrg?.name;
  const country =
    Constants.Countries[context.currentOrg?.preferences.settings?.country_code];

  return (
    <Container isCorePlan={isCorePlan}>
      <TitleContainer>
        <div className="title">
          {" "}
          <FormattedMessage
            id="trial_expire_modal.title"
            defaultMessage="Your free trial has expired"
          />
        </div>
        <div className="description">
          <FormattedMessage
            id="trial_expire_modal.description"
            defaultMessage="Start your ScreenCloud subscription in order to continue."
          />
        </div>
      </TitleContainer>
      <CoreContainer>
        <PlanTitle>
          <div className="icon">
            <img src={plans[planName].icon} />
          </div>
          <div className="plan">
            {" "}
            <FormattedMessage
              id={plans[planName].name.id}
              defaultMessage={plans[planName].name.defaultMessage}
            />
          </div>
        </PlanTitle>
        <PlanDescription>
          {" "}
          <FormattedMessage
            id={plans[planName].description.id}
            defaultMessage={plans[planName].description.defaultMessage}
          />
        </PlanDescription>
        <PriceContainer>
          <div className="from">
            {" "}
            <FormattedMessage
              id="trial_expire_modal.from"
              defaultMessage="From"
            />
          </div>
          <div className="price">
            <div className="screen-cost">
              {plans[planName].price.monthly[currency].unit}
              {price}
            </div>
            <div className="per-screen">
              <div>
                {" "}
                <FormattedMessage
                  id="trial_expire_modal.perScreenMo"
                  defaultMessage="per screen/mo"
                />
              </div>
              <div>
                {" "}
                <FormattedMessage
                  id="trial_expire_modal.plusVAT"
                  defaultMessage="+VAT"
                />
              </div>
            </div>
          </div>
          <ToggleContainer>
            {isCorePlan ? (
              <>
                <div>
                  {" "}
                  <FormattedMessage
                    id="trial_expire_modal.annual"
                    defaultMessage="Annual"
                  />
                </div>
                <Checkbox
                  toggle
                  onChange={(data, { checked }) => {
                    // monthly
                    if (checked) {
                      setPeriodUnit("month");
                      setPrice(plans[planName].price.monthly[currency].cost);
                      // yearly
                    } else {
                      setPeriodUnit("year");
                      setPrice(plans[planName].price.yearly[currency].cost);
                    }
                  }}
                />
                <div>
                  {" "}
                  <FormattedMessage
                    id="trial_expire_modal.monthly"
                    defaultMessage="Monthly"
                  />
                </div>
              </>
            ) : (
              <FormattedMessage
                id="trial_expire_modal.paidAnnually"
                defaultMessage="Paid annually"
              />
            )}
          </ToggleContainer>

          <div className="min-screen">
            {" "}
            {isCorePlan ? (
              <FormattedMessage
                id="trial_expire_modal.noMinScreens"
                defaultMessage="No minimum screens"
              />
            ) : (
              <FormattedMessage
                id="trial_expire_modal.minScreens"
                defaultMessage="5 screens minimum"
              />
            )}
          </div>
        </PriceContainer>
        <div className="divider" />
        <FeatureContainer>
          <div className="featuring">
            {" "}
            <FormattedMessage
              id={plans[planName].featureTitle.id}
              defaultMessage={plans[planName].featureTitle.defaultMessage}
            />
          </div>
          <div className="container">
            {plans[planName].features.map((feature, index) => (
              <div key={index} className="text">
                <FormattedMessage
                  id={feature.id}
                  defaultMessage={feature.defaultMessage}
                />
              </div>
            ))}
          </div>
        </FeatureContainer>
        <ButtonContainer>
          <PrimaryButton
            id="get-started-button"
            onClick={() => onGetStartedCallback(peroidUnit)}
            fullWidth={true}
          >
            <FormattedMessage
              id="trial_expire_modal.getStarted"
              defaultMessage="Get Started"
            />
          </PrimaryButton>
        </ButtonContainer>
        <Footer>
          <div
            id="talk-to-sales"
            onClick={() => {
              window.open(
                isCorePlan
                  ? getContactUrl({
                      firstName: firstName ?? "",
                      lastName: lastName ?? "",
                      email: email ?? "",
                      orgName: orgName ?? "",
                      jobTitle: jobTitle ?? "",
                      country: country ?? "",
                    })
                  : "https://screencloud.com/pricing-contact",
                "_blank",
              );
            }}
            className="talk-to-sales"
          >
            <FormattedMessage
              id="trial_expire_modal.talkToSales"
              defaultMessage="Talk to Sales"
            />
          </div>
          <Icon name="arrow-up-right-circle" />
        </Footer>
      </CoreContainer>
    </Container>
  );
};
